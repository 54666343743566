import React, { useContext } from 'react'
import Twitch from './Twitch';
import { UserContext } from './UserContext';

export default function Member() {

    const {userInfo} = useContext(UserContext);

    // const navigate = useNavigate();

    const handleSignout = () => {

        fetch("/signout")
        .then(response => {
            localStorage.clear();
            window.location.href = "/";
        })
        
    };

    return (
        
        <div>
        
            <div className="title">
                <div>歡迎光臨，這是會員頁</div>
            </div>

            <h3>{userInfo}，歡迎登入系統</h3>

            <div className="margin"><Twitch/></div>

            <span className="btn" onClick={handleSignout}>登出系統</span>

        </div>
    )
    
}
