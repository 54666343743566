import React from 'react'
import { useState } from 'react';

export default function Button() {

    const [num, setNum] = useState(0)

    return (
        <div style={{"display":"flex", "justifyContent":"center", "padding":"5px"}}>
            <div style={{"border":"2px dotted grey", "margin":"5px"}}>
                {(num === 0) ?
                    <button className="btn" onClick={ () => {setNum(num + 1)}}>開始</button> :
                <div>
                    <button style={{"width":"30px", "margin":"5px"}} onClick={() => {(num === 0) ? setNum(0) : setNum(num - 1)}}>-</button>
                    <span style={{"border":"2px solid #223344", "margin":"5px"}}> {num} </span>
                    <span style={{"border":"2px solid #223344", "margin":"5px"}}> ${num * parseInt(7.3)} </span>
                    <button style={{"width":"30px", "margin":"5px"}} onClick={ () => {setNum(num + 1)}}>+</button>
                </div>}
            </div>
        </div>

    )
}