import React from 'react'
import Button from './Button';

export default function Twitch() {

    const sweeties = [
        {"id": 1, "name": "fumi"},
        {"id": 2, "name": "hayashi"},
        {"id": 3, "name": "minako"},
        {"id": 4, "name": "chika"},
        {"id": 5, "name": "fumiko"},
        {"id": 6, "name": "kyoko"}
    ]

    return (
        <div>
            <div>
                {
                    sweeties.map(sweetie => (
                        <div key={sweetie.id}>
                            <div style={{"color":"#223344", "fontWeight":"bold"}}>{sweetie.name}</div>
                            <Button/>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
