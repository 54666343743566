import './App.css';
import Login from './Login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Member from './Member';
import Error from './Error';
import { useState, useEffect } from 'react';
import { UserContext } from './UserContext';
import Twitch from './Twitch';

function App() {

  const [userInfo, setUserInfo] = useState(null)

  useEffect(() => {

    const data = localStorage.getItem("userInfo");
    if (data) setUserInfo(JSON.parse(data))

  }, []);

  useEffect(() => {

    localStorage.setItem("userInfo", JSON.stringify(userInfo))

  }, [userInfo]);

  const localData = localStorage.getItem("userInfo");
  JSON.parse(localData)

  return (
    <div className="App">

      <BrowserRouter>

        <UserContext.Provider value={{userInfo, setUserInfo}}>

          <Routes>

            <Route path="/" element={<Login/>} />
            <Route path="/member" element={(userInfo)? <Member/> : <Error/>} />

            <Route path="/error" element={<Error/>}>
              <Route path=":message" element={<Error/>} />
            </Route>

            <Route path="twitch" element={<Twitch/>} />

            <Route path="*" element={<p>Error Page</p>} />

          </Routes>

        </UserContext.Provider>

      </BrowserRouter>

    </div>
  );
}

export default App;
