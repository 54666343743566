import React from 'react'
import Signin from './Signin'
import Signup from './Signup'

export default function Login() {

    return (
        <div>
            <Signup/>
            <Signin/>
        </div>
    )
}
