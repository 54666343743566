import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';

export default function Signin() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const {setUserInfo} = useContext(UserContext)

    const handleSignin = () => {
        
        fetch("//140.238.54.62:3000/signin", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify({
                "username": username,
                "password": password
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data.data){
                setUserInfo(data.data.name)
                navigate("/member")
            }
            else{
                navigate("/error/發生錯誤")
            }
        })

    };

    return (
        <div>
            <div id="signin">
                <div className="margin">
                    <b>登入系統</b>
                </div>
                <div className="margin">
                    <div className="margin">
                        <label>帳號 </label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => {setUsername(e.target.value)}}>
                        </input>
                    </div>
                    <div className="margin">
                        <label>密碼 </label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => {setPassword(e.target.value)}}>
                        </input>
                    </div>
                    <div className="margin">
                        <button onClick={handleSignin}>登入</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
