import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function Signup() {

    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const handleSignup = () => {

        fetch("//140.238.54.62:3000/signup", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify({
                "name": name,
                "username": username,
                "password": password
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.ok) window.location.href = "/"
            if (data.error) navigate("/error/發生錯誤")
        })

    };

    return (
        <div>
            <div id="signup">
                <div className="title">
                    <div>歡迎光臨，請註冊登入系統</div>
                </div>
                <div className="margin">
                    <b>註冊帳號</b>
                </div>
                <div className="margin">
                    <div className="margin">
                        <label>姓名 </label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => {setName(e.target.value)}}>
                        </input>
                    </div>
                    <div className="margin">
                        <label>帳號 </label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => {setUsername(e.target.value)}}>
                        </input>
                    </div>
                    <div className="margin">
                        <label>密碼 </label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => {setPassword(e.target.value)}}>
                        </input>
                    </div>
                    <div className="margin">
                        <button onClick={handleSignup}>註冊</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
