import React from 'react'
import { useParams } from 'react-router-dom'

export default function Error() {

    const params = useParams()

    return (
        <div>
            <div className="title">
                <div>失敗頁面</div>
            </div>

            <br/>

            <div>
                <h2>{params.message}</h2>
            </div>

            <br/>

            <span className="btn" onClick={ () => {window.location.href = "/"}}>返回首頁</span>

        </div>
    )
}
